import React, { useState, useEffect } from "react";
import Select from "react-select";
import { BiSearch } from "react-icons/bi";
import { BsArrowDown, BsArrowUp, BsArrowUpDown } from "react-icons/bs";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import moment from "moment";

import * as S from "../styled/styles";
import * as S2 from "./styled/styled";

import TitleC6 from "../../../components/Typography/TitleC6";
import Card from "../../../components/Cards";
import Layout from "../../../components/Layout";
import HeaderArmazem from "../../../components/HeaderArmazem";
import ModalExport from "../components/ModalExport";
import LineTableEsto from "../../../components/Lines/LineTableEsto";
import Paginate from "../../../components/Paginate/Paginate";
import ModalForm from "./components/ModalForm";
import ABCAnalysisGraph from "../../../components/Graphs/ABCAnalysisGraph";
import requests from "../../../services/requests";

import UploadIcon from "../../../assets/svgs/upload.inline.svg";
import EyeIcon from "../../../assets/svgs/eye.inline.svg";
import ExportarIcon from "../../../assets/svgs/exportar.inline.svg";
import FotoIcon from "../../../assets/svgs/foto.inline.svg";

import XpandContainer from "../../../components/XpandContainer";
const IndexPage = () => {
    const [modal, setModal] = useState(false);
    const [imageSelected, SetImageSelected] = useState(false);
    const [imageSelectedUrl, SetImageSelectedUrl] = useState("");

    const [clientsOptions, setClientsOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState(
        window.localStorage.getItem("warehouse-filter-client")
            ? JSON.parse(window.localStorage.getItem("warehouse-filter-client"))
            : []
    );
    const [noClientOptionsMessage, setNoClientOptionsMessage] = useState("Nenhum resultado encontrado");
    const [isLoadingClients, setIsLoadingClients] = useState(false);
    const [action, setAction] = useState(1);
    const [load, setLoad] = useState(false);
    const [loadModal, setLoadModal] = useState(false);
    const [list, setList] = useState([]);
    const [searchNfe, setSearchNfe] = useState(null);
    const [selectedUf, setSelectedUf] = useState(
        JSON.parse(window.localStorage.getItem("warehouse-filter-state"))?.value !== null
            ? JSON.parse(window.localStorage.getItem("warehouse-filter-state"))
            : { value: null, label: "TODOS" }
    );
    const searchPeriod = { startsAt: moment().format("YYYY-MM-DD"), endsAt: moment().format("YYYY-MM-DD") };
    const [meta, setMeta] = useState({
        current_page: 1,
    });
    const [orderList, setOrderList] = useState(null);
    const [modalExport, setModalExport] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [uploadPhotos, setUploadPhotos] = useState(false);
    const [listPhotos, setListPhotos] = useState([]);
    const [periodPhotos, setPeriodPhotos] = useState(null);
    const [statusGrafico, setStatusGrafico] = useState(null);
    const [totalCurva, setTotalCurva] = useState({});
    const [porcentCurva, setPorcentCurva] = useState({});

    const timeCourse = [
        { value: "Semanal", label: "Semanal" },
        { value: "Mensal", label: "Mensal" },
        { value: "Semestral", label: "Semestral" },
        { value: "Anual", label: "Anual" },
    ];

    const filterStyles = {
        option: () => ({
            padding: 8,
            fontSize: 15,
        }),
        control: () => ({
            height: 32,
            background: "#fff",
            borderRadius: 4,
            marginRight: 10,
            display: "flex",
            textTransform: "uppercase",
            width: 266,
            fontSize: 12,
        }),
        singleValue: () => { },
    };

    //Função que gera arquivo para exportar lista
    const downloadList = () => {
        setIsDownloading(true);
        var filters = {
            ...searchPeriod,
            idArmazem: selectedUf?.value,
            clientsWarehouse: selectedClient,
            nfeNumber: searchNfe,
            statusGrafico,
            order: orderList,
        };

        var date = new Date();

        requests
            .exportListEstoque(filters)
            .then(response => {
                const fileType = response.headers["content-type"];
                const blob = new Blob([response.data], { fileType });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", 'Estoque' + '-' + date.getFullYear() + date.getMonth() + date.getDate() + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .finally(() => {
                setIsDownloading(false);
                setModalExport(false);
            });
    };

    //Função que gera os gráficos
    const showMetrics = () => {
        var filters = {
            ...searchPeriod,
            idArmazem: selectedUf?.value,
            clientsWarehouse: selectedClient,
            statusGrafico,
        };

        requests.dashboardEstoque(filters).then(data => {
            setTotalCurva({
                curvaA: data.data.total_curva_a,
                curvaB: data.data.total_curva_b,
                curvaC: data.data.total_curva_c,
                semCurva: data.data.sem_curva,
            });

            setPorcentCurva({
                curvaA: data.data.porc_curva_a,
                curvaB: data.data.porc_curva_b,
                curvaC: data.data.porc_curva_c,
            });
        });
    };

    //Função que gera a listagem
    const listEstoque = () => {
        setLoad(true);

        var filters = {
            ...searchPeriod,
            idArmazem: selectedUf?.value,
            clientsWarehouse: selectedClient,
            nfeNumber: searchNfe,
            order: orderList,
            statusGrafico,
        };

        requests.listEstoque(filters, meta.current_page > 0 ? meta.current_page : 1).then(data => {
            setList(data.data);
            setMeta(data.meta);
            setLoad(false);
        });
    };

    //Função que gera a listagem de fotos
    const loadList = () => {
        setLoadModal(true);

        var filters = {
            clientsWarehouse: selectedClient,
            periodPhotos,
        };

        requests.getFilesListByEstoque(filters, selectedClient, meta?.current_page).then(response => {
            setListPhotos(response);
            setLoadModal(false);
        });
    };

    //Função que gera a lista de clientes do filtro
    const loadClientOptions = (search = "") => {
        setNoClientOptionsMessage("Carregando");
        setIsLoadingClients(true);

        if (selectedClient?.length > 0 && search.length < 2) {
            requests
                .listClientsWithArmazem(search)
                .then(res => {
                    var aux = [];
                    res.map(client => {
                        return (
                            aux.push({
                                value: client.identifier,
                                label: client.name + " (" + client.documentNumberFormatted + ")",
                            })
                        )
                    });
                    setClientsOptions(aux);
                    setNoClientOptionsMessage("Nenhum resultado encontrado");
                })
                .finally(() => {
                    setIsLoadingClients(false);
                });
        } else if (search.length < 2) {
            setNoClientOptionsMessage("Insira ao menos 2 caracteres para fazer a busca");
            setIsLoadingClients(false);
        } else {
            requests
                .listClientsWithArmazem(search)
                .then(res => {
                    var aux = [];
                    res.map(client => {
                        return (
                            aux.push({
                                value: client.identifier,
                                label: client.name + " (" + client.documentNumberFormatted + ")",
                            })
                        )
                    });
                    setClientsOptions(aux);
                    setNoClientOptionsMessage("Nenhum resultado encontrado");
                })
                .finally(() => {
                    setIsLoadingClients(false);
                });
        }
    };

    const actionModalExport = () => {
        setModalExport(!modalExport);
    };

    const handleImageSelected = event => {
        SetImageSelectedUrl(event.target.src);
        SetImageSelected(true);
    };

    const handleSearchNfe = number => {
        if (number.length > 3 || number.length === 0) {
            setSearchNfe(number);
        }
    };

    const handleOpenModal = () => {
        if (selectedClient?.length === 1) {
            setModal(true);
            loadList();
        } else {
            toast.error("Selecione apenas um cliente.");
        }
    };

    const validateOrder = (object, column) => {
        if (object?.order_campo === column) {
            return true;
        } else {
            return false;
        }
    };

    const handleOrder = (campo, order = 0) => {
        if (order > 0) {
            if (order === 3) {
                if (orderList?.order_campo === campo && orderList?.order === "DESC") {
                    setOrderList({
                        order_campo: campo,
                        order: "ASC",
                    });
                } else if (orderList?.order_campo === campo && orderList?.order === "ASC") {
                    setOrderList(null);
                } else {
                    setOrderList({
                        order_campo: campo,
                        order: "DESC",
                    });
                }
            } else {
                setOrderList({
                    order_campo: campo,
                    order: order === 1 ? "DESC" : "ASC",
                });
            }
        } else {
            setOrderList(null);
        }
    };

    const handleColor = (status, type) => {
        if (type === 1) {
            if (status === "A") return "#06F90E";
            else if (status === "B") return "#F8991C";
            else if (status === "C") return "#FB1B41";
            else return "";
        } else {
            if (status === "1") return "#06F90E";
            else return "#FB1B41";
        }
    };

    const handleText = text => {
        if (text === "1") return "Normal";
        else if (text === "2") return "Danificado";
        else return "";
    };

    const onChangeSetCurrentPeriod = item => {
        setPeriodPhotos(item.value);
    };

    useEffect(() => {
        loadClientOptions();

        if (searchPeriod?.startsAt !== null || searchPeriod?.endsAt !== null) {
            showMetrics();
        }
    }, []);

    useEffect(() => {
        if (searchPeriod?.startsAt !== null || searchPeriod?.endsAt !== null) {
            listEstoque();
        }
    }, [action, searchNfe, orderList, statusGrafico]);

    useEffect(() => {
        if (searchPeriod?.startsAt !== null || searchPeriod?.endsAt !== null) {
            showMetrics();
            listEstoque();
        }
    }, [selectedClient, selectedUf]);

    return (
        <>
            <Layout>
                <ModalExport
                    modalExport={modalExport}
                    actionModalExport={actionModalExport}
                    isDownloading={isDownloading}
                    downloadList={downloadList}
                    title="Estoque"
                />

                <HeaderArmazem
                    title="Armazém > Estoque"
                    clientsOptions={clientsOptions}
                    setSelectedClient={setSelectedClient}
                    noClientOptionsMessage={noClientOptionsMessage}
                    loadClientOptions={loadClientOptions}
                    isLoadingClients={isLoadingClients}
                    setSelectedUf={setSelectedUf}
                    setMeta={setMeta}
                    handleStatusGrafico={setStatusGrafico}
                    handleStatusPedido={setStatusGrafico}
                    isByPeriod={false}
                />

                <div className="p-4">
                    <ABCAnalysisGraph
                        modality="ESTOQUE"
                        title="Curva ABC - Estoque"
                        searchPeriod={searchPeriod}
                        selectedClient={selectedClient}
                        selectedUf={selectedUf}
                        statusGrafico={statusGrafico}
                        handleStatus={setStatusGrafico}
                        setMeta={setMeta}
                    />
                </div>

                <div className="p-4">
                    <Card>
                        <S.Table>
                            <S.TableHeader>
                                <S.ContainerFluid>
                                    <S.ContainerSection>
                                        <TitleC6>Estoque</TitleC6>
                                        <S.ContainerSearch>
                                            <S.ContainerInput>
                                                <BiSearch color="#00000087" />
                                                <S.Input
                                                    placeholder="Buscar por código ou descrição do Produto"
                                                    onChange={event => handleSearchNfe(event.target.value)}
                                                />
                                            </S.ContainerInput>
                                        </S.ContainerSearch>
                                    </S.ContainerSection>
                                    <S.ContainerSection>
                                        <S.ButtonIcon className="d-block float-right" onClick={handleOpenModal}>
                                            <S.ButtonIconText>Fotos</S.ButtonIconText>
                                            <FotoIcon />
                                        </S.ButtonIcon>

                                        <S.ButtonIcon>
                                            <S.ButtonIconText
                                                className="d-block float-right"
                                                onClick={() => setModalExport(!modalExport)}
                                            >
                                                Exportar
                                            </S.ButtonIconText>
                                            <ExportarIcon />
                                        </S.ButtonIcon>
                                    </S.ContainerSection>
                                </S.ContainerFluid>
                            </S.TableHeader>
                            <S.ContainerLines>
                                <XpandContainer>
                                    <S.LineHeadTable>
                                        <S.ColumnHeadTable width="250">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.codProduto", 3)}
                                                cursor
                                            >
                                                Cód. Produto
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.codProduto") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.codProduto", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp
                                                            size={20}
                                                            onClick={() => handleOrder("e.codProduto", 0)}
                                                        />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.codProduto", 1)}
                                                    />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>

                                        <S.ColumnHeadTable width="500">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.descProduto", 3)}
                                                cursor
                                            >
                                                Descrição
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.descProduto") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.descProduto", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp
                                                            size={20}
                                                            onClick={() => handleOrder("e.descProduto", 0)}
                                                        />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.descProduto", 1)}
                                                    />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="200" paddingLeft="20">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.setor", 3)}
                                                cursor>
                                                Setor
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.setor") ? (
                                                    orderList.order == "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.setor", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.setor", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.setor", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>

                                        <S.ColumnHeadTable width="210" paddingLeft="20">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.situacao", 3)}
                                                cursor
                                            >
                                                Estado do produto
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.situacao") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.situacao", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.situacao", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.situacao", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="190">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.qtdeAvariada", 3)}
                                                cursor
                                            >
                                                Estoque disponível
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.qtdeAvariada") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.qtdeAvariada", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp
                                                            size={20}
                                                            onClick={() => handleOrder("e.qtdeAvariada", 0)}
                                                        />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.qtdeAvariada", 1)}
                                                    />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="190">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.unityValue", 3)}
                                                cursor>
                                                Valor
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.unityValue") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.unityValue", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp
                                                            size={20}
                                                            onClick={() => handleOrder("e.unityValue", 0)}
                                                        />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.unityValue", 1)}
                                                    />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="190">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.idLoteFabricacao", 3)}
                                                cursor
                                            >
                                                Lote indústria
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.idLoteFabricacao") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.idLoteFabricacao", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp
                                                            size={20}
                                                            onClick={() => handleOrder("e.idLoteFabricacao", 0)}
                                                        />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.idLoteFabricacao", 1)}
                                                    />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="130">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.ufArmazem", 3)}
                                                cursor>
                                                Armazém
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.ufArmazem") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.ufArmazem", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.ufArmazem", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.ufArmazem", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="170">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.dtVencimento", 3)}
                                                cursor
                                            >
                                                Vencimento
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.dtVencimento") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.dtVencimento", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp
                                                            size={20}
                                                            onClick={() => handleOrder("e.dtVencimento", 0)}
                                                        />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.dtVencimento", 1)}
                                                    />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="170" align="center">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.inAbc", 3)}
                                                cursor
                                            >
                                                Curva ABC
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.inAbc") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.inAbc", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.inAbc", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.inAbc", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="300">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.client", 3)}
                                                cursor
                                            >
                                                Depositante
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.client") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.client", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.client", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.client", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                    </S.LineHeadTable>
                                    {!load && (
                                        <>
                                            {list &&
                                                list.map((entity, key) => {
                                                    return (
                                                        <LineTableEsto
                                                            entity={entity}
                                                            handleColor={handleColor}
                                                            handleText={handleText}
                                                        />
                                                    );
                                                })}
                                        </>
                                    )}
                                    {load ? (
                                        <div className="text-center mt-4">
                                            <ClipLoader size={40} loading={load} />
                                        </div>
                                    ) : (
                                        <>
                                            {list.length === 0 && (
                                                <div className="text-center mt-4">Nenhum dado encontrado</div>
                                            )}
                                        </>
                                    )}
                                    {meta.total_pages > 1 && (
                                        <Paginate
                                            meta={meta}
                                            setMeta={setMeta}
                                            action={action}
                                            setAction={setAction}
                                            showDetails={true}
                                        />
                                    )}
                                </XpandContainer>
                            </S.ContainerLines>
                        </S.Table>
                    </Card>
                </div>
            </Layout>
            {modal && (
                <S2.ContainerModal>
                    <S2.Modal>
                        <S2.ModalHeader>
                            <S2.ModalHeaderTitle>Fotos do estoque</S2.ModalHeaderTitle>
                            <S2.CloseIcon
                                onClick={() => {
                                    setModal(false);
                                    setUploadPhotos(false);
                                    setPeriodPhotos(null);
                                }}
                            >
                                X
                            </S2.CloseIcon>
                        </S2.ModalHeader>
                        <S.ContainerFilters justify="sim">
                            <S2.Filters>
                                {!uploadPhotos && (
                                    <Select
                                        options={timeCourse}
                                        id="client"
                                        name="client"
                                        className="outline-none transform-none"
                                        placeholder="Período"
                                        onChange={item => onChangeSetCurrentPeriod(item)}
                                        styles={filterStyles}
                                    />
                                )}
                            </S2.Filters>
                            <S2.ButtonUpload
                                width={uploadPhotos ? "130" : "108"}
                                onClick={() => setUploadPhotos(!uploadPhotos)}
                            >
                                <S2.ButtonIconText>{uploadPhotos ? "Visualizar" : "Upload"}</S2.ButtonIconText>
                                {uploadPhotos ? <EyeIcon /> : <UploadIcon />}
                            </S2.ButtonUpload>
                        </S.ContainerFilters>
                        {uploadPhotos ? (
                            <ModalForm client={selectedClient} onSuccess={loadList} />
                        ) : (
                            <S2.ContainerPhotos>
                                <S2.GridPhotos>
                                    {!loadModal && (
                                        <>
                                            {listPhotos &&
                                                listPhotos.map((entity, key) => {
                                                    return (
                                                        <S2.BoxPhoto
                                                            src={entity?.file_url}
                                                            onClick={handleImageSelected}
                                                            onError={e => {
                                                                e.target.src = entity?.file_url;
                                                            }}
                                                            alt={entity?.name}
                                                        />
                                                    );
                                                })}
                                        </>
                                    )}
                                    {loadModal ? (
                                        <div className="text-center mt-4">
                                            <ClipLoader size={90} loading={loadModal} />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </S2.GridPhotos>
                                {imageSelected && (
                                    <S2.BoxPhotoSelected>
                                        <S2.ClosePhotoSelected onClick={() => SetImageSelected(false)}>
                                            X
                                        </S2.ClosePhotoSelected>
                                        <S2.PhotoSelected src={imageSelectedUrl} />
                                    </S2.BoxPhotoSelected>
                                )}
                            </S2.ContainerPhotos>
                        )}
                    </S2.Modal>
                </S2.ContainerModal>
            )}
        </>
    );
};

export default IndexPage;
