import styled from "styled-components";

export const ContainerModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 64px);
    z-index: 52;
    background: #50505066 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Modal = styled.div`
    background: #f5f5f5;
    width: 100%;
    max-width: 1002px;
    height: 90vh;
    border-radius: 12px;
    padding: 26px 41px;
    box-sizing: border-box;
    overflow-y: scroll;
`;

export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const ModalHeaderTitle = styled.p`
    text-transform: uppercase;
    font-weight: 500;
    color: #707070;
    font-size: 16px;
`;

export const CloseIcon = styled(ModalHeaderTitle)`
    cursor: pointer;
    margin-bottom: 10px;
`;

export const Filters = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 570px;
`;

export const ButtonUpload = styled.div`
    width: ${props => (props.width ? props.width : "108")}px;
    background: #fff;
    border-radius: 4px;
    display: inline-block;
    padding: 5px 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    height: 32px;
    align-items: center;
    & + & {
        margin-left: 7px;
    }
`;

export const ButtonIconText = styled.p`
    color: #707070;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 7px;
`;

export const ContainerPhotos = styled.div`
    background: #fff;
    border-radius: 4px;
    padding: 23px 25px;
    position: relative;
`;

export const PhotosHeader = styled.div`
    margin-bottom: 24px;
`;
export const PhotosHeaderText = styled.p`
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
`;

export const GridPhotos = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const BoxPhoto = styled.img`
    width: 224px;
    height: 179px;
    margin-bottom: 34px;
    object-fit: cover;
    cursor: pointer;
`;

export const BoxPhotoIframe = styled.iframe`
    width: 224px;
    margin-bottom: 34px;
    object-fit: contain;
    cursor: pointer;
    background-color: red;
    html {
        background-color: red;
    }
    img:before {
        background-color: red;
        font-size: 1000px;
    }
`;

export const BoxPhotoSelected = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #fff;
    border-radius: 4px;
    padding: 20px;
`;

export const PhotoSelected = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

export const ClosePhotoSelected = styled.p`
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
`;

export const ButtonExport = styled.button`
    color: #2a2a2a;
    font-size: 10px;
    border: 1px solid #70707080;
    background: #fff;
    padding: 7px 17px;
    border-radius: 4px;
`;

export const ButtonIcon = styled.div`
    height: 2rem;
    border: 1px solid #909090;
    border-radius: 4px;
    display: inline-block;
    padding: 0.32rem 1.2rem;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    & + & {
        margin-left: 0.44rem;
    }
`;
