import React from "react";

import * as S from "../styles";

const LineTableEsto = ({ entity, handleColor, handleText }) => {
    return (
        <S.LineBodyTable className="border-b-2">
            <S.Columns style={{ cursor: "auto" }}>
                <S.ColumnBodyTable width="250">
                    <S.LineBodyText>{entity?.codProduto} </S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="500">
                    <S.LineBodyText>{entity?.descProduto}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="200" paddingLeft="20">
                    <S.LineBodyText>{entity?.setor}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="210" paddingLeft="20">
                    <S.LineBodyText color={handleColor(entity?.situacao, 2)}>{handleText(entity?.situacao)}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="190" align="left" paddingRight="40">
                    <S.LineBodyText>{entity?.situacao === '1' ? entity?.qtdeEstoqueFormatted : entity?.qtdeAvariadaFormatted}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="190" align="left" paddingRight="40">
                    <S.LineBodyText>{entity?.totalValueFormatted}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="190">
                    <S.LineBodyText>{entity?.idLoteFabricacao}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="120">
                    <S.LineBodyText>{entity?.stateCodeFormatted}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="170">
                    <S.LineBodyText>{entity?.dtVencimentoFormatted}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="170" align="center">
                    <S.LineBodyText color={handleColor(entity?.inAbc, 1)}>{entity?.inAbc}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="300" paddingLeft="15">
                    <S.LineBodyText>{entity?.clientName}</S.LineBodyText>
                </S.ColumnBodyTable>
            </S.Columns>
        </S.LineBodyTable>
    );
};

export default LineTableEsto;
